import style from './Sliders.module.css';

import { ReactComponent as Wapp } from '../../images/Wapp.svg';
import { ReactComponent as Tg } from '../../images/Tgs.svg';

import { useMediaQuery } from 'react-responsive';
const Sliders = () => {
 
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  
//watsapp
      const handleWa = () => {
        const url = "https://api.whatsapp.com/message/232ZXS3OASO5P1?autoload=1&app_absent=0";
        window.open(url, '_blank'); 
      };
      
// telegram
      const handleTg = () => {
        const url = "https://t.me/LaStik444";
        window.open(url, '_blank'); 
      };


    return (
      <div className={style.Mobile}>
        <div className={style.sliders}>
         
             <div className={style.containerWeb}>
            <div className={style.title}>
                <div className={style.slideLogo}>Профессиональная уборка <br className={style.mobile} />за адекватные деньги</div>
                <div className={style.slideTitle}>Архангельск, Северодвинск, Новодвинск</div>
            </div>

            <div className={style.btns}>
            <button type="button" className={style.btnTg} onClick={handleTg}>
                   <Tg className={style.tg} />
              </button>


              <button type="button" className={style.btnWapp} onClick={handleWa}>
                 <Wapp className={style.wapp} />
              </button>

             
            </div>
            </div>







        
       </div>
       </div>
    )
}

export default Sliders;