import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';

import { useForm } from 'react-hook-form';
import { ServiceModal } from '../../../pages/main/MainPage/ServiceModal';
import { ReactComponent as RadioFalse } from '../../../images/radioFalse.svg';
import { ReactComponent as RadioTrue } from '../../../images/radioTrue.svg';
import { ReactComponent as BtnBacks } from '../../../images/btnBacks.svg';
import { ReactComponent as BtnForward } from '../../../images/btnForward.svg';
import SliderOne from '../../../images/slidermobile1.jpg';
import SliderTwo from '../../../images/slidermobile2.jpg';
import SliderThree from '../../../images/slidermobile3.jpg';
import SliderFour from '../../../images/slidermobile4.jpg';
import SlidePaysOne from '../../../images/paysmobile1.jpg';
import SlidePaysTwo from '../../../images/paysmobile2.jpg';
import SlidePaysThree from '../../../images/paysmobile3.jpg';
import {LABOR_NEW_ID} from "../../../api/model/labor";
import style from "./Cards.module.scss"
import {selectCalculationResult, selectLaborById} from "../../../redux/reducers/calculator/calculatorSelectors";


const CardsMobile = () => {
    // типизация данных для отправки с формы
type TFormValues = {
    phone: string;
    name: string;
    type: string;
  };
  const [openService, setOpenService] = useState(false);
    const dispatch = useAppDispatch();
    const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });
  
    const [openMenu, setOpenMenu] = useState(false);
  
    const [check, setCheck] = useState(false);
 
 
    const [currentIndex, setCurrentIndex] = useState(0);

    const [imageSlide, setImageSlide] = useState<string[]>([]);
    const [images, setImages] = useState<string[]>([]);
    const [isAnimating, setIsAnimating] = useState(false); // Флаг анимации
    const [currentPaysIndex, setCurrentPaysIndex] = useState(0);
   
    const selectCalculationResultByIdNew = selectCalculationResult(LABOR_NEW_ID);
    const selectLaborByIdNew = selectLaborById(LABOR_NEW_ID)
    const calculationResult = useAppSelector(selectCalculationResultByIdNew);
    const labor = useAppSelector(selectLaborByIdNew);
  
    const api = `https://402.m-dels.ru/order`;
  
    // загружаем для слайдера блока слайдер все изображения
    const getImageSlide = (): string[] => {
      return [SliderOne, SliderTwo, SliderThree, SliderFour];
    };
  
    // загружаем для слайдера блока 2 все изображения
    const getImage = (): string[] => {
      return [SlidePaysOne, SlidePaysTwo, SlidePaysThree];
    };
  
    // определяем стиль для индекса изображения блока слайдера (активный, следующий и предыдущий, остальные скрываем)

  
    // определяем стиль для индекса изображения блока 2 (активный, следующий и предыдущий, остальные скрываем)
    const getPositionClass = (index: number): string => {
      if (index === currentPaysIndex) return style.active;
      if (index === (currentPaysIndex + 1) % images.length) return style.next;
      if (index === (currentPaysIndex - 1 + images.length) % images.length) return style.prev;
    };
  
    // переключение слайдера вперед
 
    // переключение слайдера назад

    // переключаме слайдера второго блока вперед
    const handleClickForwardPays = () => {
      if (isAnimating) return; // Если анимация идет, ничего не делаем
      setIsAnimating(true);
  
      // Сбрасываем флаг анимации после завершения
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentPaysIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 500);
    };
  
    // переключение слайдера второго блока назад
    const handleClickBackPays = () => {
      if (isAnimating) return;
      setIsAnimating(true);
  
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentPaysIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      }, 500);
    };


  
    // Устанавливаем пути к изображениям в стейт для доступа к ним в JSX
    useEffect(() => {
      const imageSourcesSlider = getImageSlide();
      const imageSources = getImage();
  
      setImageSlide(imageSourcesSlider);
      setImages(imageSources);
    }, []);
  
    // Меняем каждые 5 секунд слайд блока слайдер
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (currentIndex === 3) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex((prevIndex) => (prevIndex + 1));
        }
  
      }, 5000);
  
      return () => clearInterval(intervalId);
    }, [currentIndex]);
  
    // Меняем каждые 5 секунд слайд блока 2
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (currentPaysIndex === 2) {
          setCurrentPaysIndex(0);
        } else {
          setCurrentPaysIndex((prevIndex) => (prevIndex + 1));
        }
  
      }, 5000);
  
      return () => clearInterval(intervalId);
    }, [currentPaysIndex]);
  
  return (
    <div className={style.Mobile}>
    <div className={style.pays} id="pays">
    {images.map((item, index) => {
        const position = getPositionClass(index);
        return (
          <img
            key={index}
            className={`${style.slidePaysImg} ${position}`}
            src={item}
            alt={`Slide number ${index + 1}`}
            />
        );
      })}
      {currentPaysIndex === 0 ? (
        <>
          <div className={style.titleOne}>Квартира</div>
          <div className={style.textOne}>от 950 ₽</div>
        </>
      ) : currentPaysIndex === 1 ? (
        <>
          <div className={style.titleTwo}>Торговая точка</div>
          <div className={style.textTwo}>от 900 ₽</div>
        </>
      ) : currentPaysIndex === 2 ? (
        <>
          <div className={style.titleThree}>Офис</div>
          <div className={style.textThree}>от 900 ₽</div>
        </>
      ) : null}
      <button className={style.btnBack} type="button" onClick={handleClickBackPays}>
        <BtnBacks />
      </button>
      <button className={style.btnForward} type="button" onClick={handleClickForwardPays}>
        <BtnForward />
      </button>
      <button className={style.btn} type="button" onClick={() => setOpenService(true)}>Заказать услугу</button>
      <div className={style.sliderPays}>
        <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(0)}>
          {currentPaysIndex === 0 ? <RadioTrue /> : <RadioFalse className={style.false} />}
        </button>
        <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(1)}>
          {currentPaysIndex === 1 ? <RadioTrue /> : <RadioFalse className={style.false} />}
        </button>
        <button className={style.btnPays} type="button" onClick={() => setCurrentPaysIndex(2)}>
          {currentPaysIndex === 2 ? <RadioTrue /> : <RadioFalse className={style.false} />}
        </button>
      </div>
      <ServiceModal open={openService} onClose={() => setOpenService(false)} />
  </div>
  </div>
  )
}

export default CardsMobile 