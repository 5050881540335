import { TOrderResponseAlls, TOrderResponseSetOrder } from '../../../response/orderResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TOrder = {
  isFetching: boolean;
	isFetchingOrder: boolean;
  error: string;
  orderData: {};
	order_ido: string;
}

const initialState: TOrder = {
  isFetching: false,
	isFetchingOrder: false,
	error: '',
	orderData: {'1': {
		start_datetime: '',
		room_type: '',
		fias_address: '',
		room_square: 0,
		service: '',
    subservice: '',
    additional_list: [],
		order_status: '',
		client_ido: '',
		order_ido: '',
		cleaner_ido: '',
		set_cleaners: [],
	}},
	order_ido: '',
};

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		// start
		orderFetching(state) {
			state.isFetching = true;
		},
		orderFetchingOrder(state) {
			state.isFetchingOrder = true;
		},
		// succes
		orderGetOrders(state, action: PayloadAction<TOrderResponseAlls>) {
			state.isFetching = false;
			state.orderData = action.payload;
			state.error = '';
		},
		orderSetOrder(state, action: PayloadAction<TOrderResponseSetOrder>) {
			state.isFetching = false;
			state.isFetchingOrder = false;
			state.order_ido = action.payload;
			state.error = '';
		},
		// Error
    orderError(state, action: PayloadAction<string>) {
			state.isFetching = false;
			state.isFetchingOrder = false;
      state.error = action.payload;
    },
		// reset state
		orderReset() {
			return initialState;
		},
	},
});

export default orderSlice.reducer;
