
import  { useState, useEffect } from 'react';
import './BurgerMenu.css'; 
import { ReactComponent as Close } from '../../../images/close.svg';
const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolls, setScrolls] = useState(0);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
       
    const handleScroll = () => {
      setScrolls(window.scrollY);
   
      
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, []);
  return (
    <div className="burger-menu">
      <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className={scrolls ? 'lineScrolled' : 'line'}></div>
        <div className={scrolls ? 'lineScrolled' : 'line'}></div>
        <div className={scrolls ? 'lineScrolled' : 'line'}></div>
        <div className={scrolls ? 'lineScrolled' : 'line'}></div>
      </div>
      {isOpen && (
        <nav className="menu">
            
          <ul style={{marginTop:'30px'}}>
            <li ><a onClick={toggleMenu} href="#formorders">Заказать уборку</a></li>
            <li><a onClick={toggleMenu} href="#cards">Уборки</a></li>
            <li><a onClick={toggleMenu} href="#calculates">Калькулятор</a></li>
            <li><a onClick={toggleMenu} href="#spoilers">Вопросы</a></li>
            <li><a onClick={toggleMenu} href="#footer">Контакты</a></li>
            <li className='lk'><a href="#services">Личный кабинет</a></li>
           
          </ul>
          <div className='x' onClick={toggleMenu}><Close/></div>
        </nav>
      )}
    </div>
  );
};

export default BurgerMenu;