
import MainPage from './MainPage';


/**
 * @function {Main} - Основной компонент заглавной страницы для приложения "Клининг". 
 * В зависимости от медиа-запроса возвращает десктопную или мобильную версиюю.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий заглавную страницу.
 */

function Main(): React.JSX.Element {


  return (
    <>
      
        <MainPage />
     
    </>
  );
};

export default Main;
