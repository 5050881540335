import React from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    updateLabor,
} from "../../../redux/reducers/calculator/calculatorSlice";
import dateToUTCString from "../../../api/convertors/dateToUTCString";
import styled from "styled-components";
import {isTimePrefferedByWorkHours} from "../../../api/calculator";
import {selectLaborById} from "../../../redux/reducers/calculator/calculatorSelectors";
import useLaborContext from "../../../api/laborcontext/LaborContext";
import {useQuery} from "react-query";
import {apiClient} from "../../../api/auth";
import PreferDateDto from "../../../api/model/preferdate";


/**
 * @Composable
 * Виджет выбора даты заказа у калькулятора
 * @constructor
 */
function CalculatorDateTimeWidget() : React.JSX.Element {
    const dispatch = useAppDispatch();
    // TODO оптимизировать селекцию всех виджетов (не все поля, а конкретные)
    const { laborId } = useLaborContext();
    const labor = useAppSelector(selectLaborById(laborId));

    const {
        data: preferdateData,
        isLoading: preferdateIsLoading
    } = useQuery(
        ["preferdate"],
        async () => apiClient.get<PreferDateDto>("/order/preferdate/" + encodeURI(new Date().toISOString())),
        {
            refetchOnWindowFocus: false
        }
    )

    const nextPreferDate: Date | null = preferdateIsLoading ? null : new Date(preferdateData.data.next_datetime)

    // Подблок 1. Дата и время
    function handleChangeDateTime(event) {
        if (!event.target['validity'].valid) {
            return;
        }
        //const isoString = event.target['value'] + '355Z';
        const dateTime = new Date(event.target['value']);

        if (nextPreferDate && nextPreferDate <= dateTime && isTimePrefferedByWorkHours(dateTime)) {
            dispatch(updateLabor({ id: laborId, start_datetimeEpoch : dateTime.getTime() }))
        }
    }

    return (
        preferdateIsLoading ? (<></>) : (
        <ScCalculatorDateTimeWidget type="datetime-local"
            value={dateToUTCString(new Date(labor.start_datetimeEpoch))}
            onChange={handleChangeDateTime}
            min={dateToUTCString(nextPreferDate)}
            onClick={(e) => e.currentTarget.showPicker() }
        />)
    )
}

const ScCalculatorDateTimeWidget = styled.input`
    border: 1px solid ${props => props.theme.colors.secondary}aa;
    border-radius: ${props => props.theme.rounded.normal};

    min-height: 3em;
    font-size: inherit;
    color: inherit;

    padding: 0 1em;

    &:focus {
        outline: none;
    }

    &:invalid[type="datetime-local"] {
        color: red;
    }

`

export default CalculatorDateTimeWidget;