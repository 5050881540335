import orderApi from "./orderApi";
import WalletDto from "../../../api/model/wallet";


const walletEndpoints = orderApi.injectEndpoints({
    endpoints: (builder) => ({
        getWallet: builder.query<WalletDto, void>({
            query: () => ({ url: '/profile/wallet', method: 'get' }),
            providesTags: ['Wallet']
        })
    })
})

export const { useGetWalletQuery } = walletEndpoints;
export default walletEndpoints;