import s from './ThirdCard.module.css'
import { ServiceModal } from '../../../pages/main/MainPage/ServiceModal';
import { useState } from 'react';

const ThirdCard = () => {
    const [openService, setOpenService] = useState(false);
  return (

    <div className={s.cardContainer}>
        
        <div className={s.logo}> 
            Офис
        </div>
        
        <div className={s.title}>
            <div className={s.titleSum}>
            от 900 ₽
            </div>
            <button onClick={() => setOpenService(true)} className={s.titleBtn}>
                Заказать услугу
            </button>
        </div>
        
        
        
        
        
        
        
        
        <ServiceModal open={openService} onClose={() => setOpenService(false)} />
    </div>
  )
}

export default ThirdCard