import useAuthContext from "../api/authcontext/AuthContext";
import {ReactElement} from "react";
import {Navigate, Outlet} from "react-router-dom";

/**
 * @Composable
 * Обёртка для удостоверения, что пользователь авторизован
 * @constructor
 */
function EnsureAuthorized() : ReactElement {
    const authCtx = useAuthContext();

    return authCtx.userCtx.user === null ? <Navigate to="/signin" replace /> : <Outlet/>;
}

export default EnsureAuthorized;