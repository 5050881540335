import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {BaseQueryFn} from "@reduxjs/toolkit/query/react";


export interface AxiosBaseQueryItem {
    url?         : string
    method?     : AxiosRequestConfig['method']
    data?       : AxiosRequestConfig['data']
    params?     : AxiosRequestConfig['params']
    headers?    : AxiosRequestConfig['headers']
}

export type AxiosBaseQueryFn = BaseQueryFn<AxiosBaseQueryItem, unknown, unknown>
export type AxiosBaseQueryBuilderArgs = {
    /**
     * Экземпляр axios
     */
    axiosInstance: AxiosInstance,
    /**
     * Префикс, который устанавливается перед URL
     */
    urlPrefix: string
}
const AxiosBaseQueryBuilderArgsDefaults : AxiosBaseQueryBuilderArgs = { axiosInstance: axios.create() , urlPrefix: '' }

export const buildBaseQueryWithAxiosInstance =
    (
        { axiosInstance, urlPrefix } : AxiosBaseQueryBuilderArgs = AxiosBaseQueryBuilderArgsDefaults
    ): AxiosBaseQueryFn =>
    async ({ url, method, data, params, headers }, api ) => {
        try {
            const result = await axiosInstance({ url : urlPrefix + (url || ''), method, data, params, headers });
            return { data: result.data }
        }
        catch (axiosError) {
            const err = axiosError as AxiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                }
            }
        }
    }

export default buildBaseQueryWithAxiosInstance;