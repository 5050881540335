export enum Role {
    INDIVIDUAL = 'individual',
    CORPORATE = 'corporate',
    CLEANER = 'cleaner',
    ADMIN = 'admin'
}

export interface UserContext {
    user?: IndividualUserDto | null;
}

export const UserContextDefaults = {
    user: null
}


export class BaseUserDto {
    public phone: string;
    public email: string;
    public role: Role;
    public avatar_rid?: string;

    constructor(data: Partial<IndividualUserDto>) {
        Object.assign(this, data)
    }
}


export class IndividualUserDto extends BaseUserDto {
    public person_name: string;
    public person_surname: string;
    public person_patronym: string

    constructor(data: Partial<IndividualUserDto>) {
        super(data)
        Object.assign(this, data)
    }
}