import * as React from 'react';
import { Select, selectClasses, SelectRootSlotProps } from '@mui/base/Select';
import { optionClasses } from '@mui/base/Option';
import { useTheme } from 'styled-components';
import SelectArrowIcon from './icons/SelectArrowIcon';


type SelectFieldProps = {
    multiple?: boolean;
    value?: string,
    onChange?: (oldValue: any, newValue: any) => void,
    children?: React.ReactNode;
}

const SelectFieldsDefaults : SelectFieldProps = {
    multiple: false
}

/**
 * @Composable
 * Компонент выпадающего списка
 * @constructor
 */
export function SelectField(props : SelectFieldProps = SelectFieldsDefaults) {
    return (
        <>
            <Select
                className="CustomSelect"
                slots={{
                    root: Button,
                }}
                slotProps={{
                    listbox: { className: 'CustomSelect-listbox' },
                    popup: { className: 'CustomSelect-popup' },
                }}
                {...props}
            >
            </Select>
            <Styles />
        </>
    );
}

const Button = React.forwardRef(function Button<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const { ownerState, ...other } = props;
    return (
        <button type="button" {...other} ref={ref}>
            {other.children}
            <SelectArrowIcon/>
        </button>
    );
});


function Styles() {
    const theme = useTheme();
    const minWidth = '100px';
    const backgroundColor = theme.colors.surface;

    return (
        <style>
            {`
      .CustomSelect {
        position: relative;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        min-width: ${minWidth};
        padding: 8px 12px;
        border-radius: 8px;
        text-align: left;
        line-height: 1.5;
        background: ${backgroundColor};
        border: 1px solid ${theme.colors.secondary}aa;
        color: ${theme.colors.secondary};

        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;

        &.${selectClasses.focusVisible} {
          outline: 0;
          border-color: ${theme.colors.primary};
        }

        & > img {
          font-size: 1rem;
          position: absolute;
          height: 70%;
          top: 15%;
          right: 10px;
          transition: transform 0.25s ease-in-out;
        }
        
        &.${selectClasses.expanded} > img {
          transform: rotate(180deg);         
          -moz-transition: all .5s;
          -webkit-transition: all .5s;
          -o-transition: all .5s;
          -ms-transition: all .5s;
        }
      }
      .CustomSelect-listbox {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 6px;
        margin: 12px 0;
        min-width: 285px;
        border-radius: 12px;
        overflow: auto;
        outline: 0px;
        background: ${backgroundColor};
        border: 1px solid ${theme.colors.secondary}aa;
        color: ${theme.colors.secondary};
      }        
      @media screen and (max-width:1224px) {
      .CustomSelect-listbox{
        min-width: 388px;
      }
      }
      .CustomSelect-popup {
        z-index: 1;
      }
      .CustomSelect-listbox > * {
        list-style: none;
        padding: 8px;
        border-radius: 8px;
        cursor: default;

        &:last-of-type {
          border-bottom: none;
        }

        &.${optionClasses.selected} {
          background-color: ${theme.colors.primary};
          color: ${theme.colors.primaryContainer};
        }
        
        &.${optionClasses.highlighted}:not(.${optionClasses.selected}) { 
          color: ${theme.colors.primary};
        }
        
        &:focus-visible {
          outline: 1px solid ${theme.colors.primary};
        }

        &.${optionClasses.disabled} {
          color: ${theme.colors.tertiary};
        }

        &:hover:not(.${optionClasses.disabled}):not(.${optionClasses.selected}) {
          background-color: ${theme.colors.secondaryContainer};
        }    
      }
      `}
        </style>
    );
}

export default SelectField;
