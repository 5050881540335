import * as React from "react";
import auth from "../auth";
import {AppDispatch} from "../../redux/store";
import {loadingSlice} from "../../redux/reducers/loading";
import {useCallback} from "react";
import {useAppDispatch} from "../../hooks/redux";
import {IndividualUserDto, UserContext, UserContextDefaults} from "../model/baseUser";
import { CurrentContext } from "./AuthContext";

type UserProviderProps = {
    children: React.ReactNode
}

export const AuthContextProvider = (props: UserProviderProps) => {
    const [userContext, setUserContext] = React.useState<UserContext>(UserContextDefaults);
    const dispatch : AppDispatch = useAppDispatch();

    const ensureUser = useCallback(async () : Promise<boolean> => {
        if (userContext.user) {
            return true;
        }

        try {
            const isSuccess = await auth.ensureSignedIn()

            dispatch(loadingSlice.actions.setHint("Запрос токенов"))
            if (!isSuccess) {
                return false;
            }

            const response = await auth.apiClient.get("/profile")

            setUserContext({ user: new IndividualUserDto(response.data) });

            return true;
        }
        catch (e) {
            console.error(e)

            return false;
        }
    }, []);

    return (
        <CurrentContext.Provider value={{userCtx: userContext, setUserCtx: setUserContext, ensureUser}}>
            {props.children}
        </CurrentContext.Provider>
    )
};

export default AuthContextProvider