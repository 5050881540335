import s from './Spoilers.module.css';
import { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import 
{ 
  spoilerOneTrue,
  spoilerTwoTrue,
  spoilerThreeTrue,
  spoilerFourTrue,
  spoilerFiveTrue,
  spoilersReset,
} from '../../redux/reducers/mainPage/flagActions';
import { flagSelectors } from '../../redux/reducers/mainPage/flagSelectors';


const Spoilers = () => {
    const dispatch = useAppDispatch();
    const [openSpoiler, setOpenSpoiler] = useState<string | null>(null);
    const { spoilerOne, spoilerTwo, spoilerThree, spoilerFour, spoilerFive } = useAppSelector(flagSelectors);

    // функция для переключения спойлеров
  const handleSpoiler = (name: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (openSpoiler === name) {
      setOpenSpoiler(null);
      dispatch(spoilersReset());
    } else {
      setOpenSpoiler(name);
      switch(name) {
        case "spoilerOne": dispatch(spoilerOneTrue()); break;
        case "spoilerTwo": dispatch(spoilerTwoTrue()); break;
        case "spoilerThree": dispatch(spoilerThreeTrue()); break;
        case "spoilerFour": dispatch(spoilerFourTrue()); break;
        case "spoilerFive": dispatch(spoilerFiveTrue()); break;
        default: break;
      }
    }
  };

  return (
<div id='spoilers' className={s.Mobile}>
    <div className={s.spoilersContainer}>
        <div className={s.logo}>Частые вопросы</div>
        <div className={s.spoilers}>
          <details className={s.spoiler} open={openSpoiler === 'spoilerOne'}>
            <summary className={s.spoilerTitle} onClick={(event) => handleSpoiler('spoilerOne', event)}>Как сделать заказ на уборку?</summary>
            {spoilerOne ?
              <div className={s.spoilerSubTitle}>Нажмите значок WA или Telegram, напишите тип объекта, вид уборки (генеральная, поддерживающая или послестроительная) напишите желаемую дату и время. При необходимости ответьте на дополнительные вопросы менеджера. Также вы можете оставить запрос на обратный звонок.</div>
            : null}
          </details>
          <details className={s.spoiler} open={openSpoiler === 'spoilerTwo'}>
            <summary className={s.spoilerTitle} onClick={(event) => handleSpoiler('spoilerTwo', event)}>Я могу оставить клинера одного в квартире??</summary>
            {spoilerTwo ?
              <div className={s.spoilerSubTitle}>Да, можете оставить клинера в квартире одного, при этом за полчаса до окончания, он с Вами свяжется.</div>
            : null}
          </details>
          <details className={s.spoiler} open={openSpoiler === 'spoilerThree'}>
            <summary className={s.spoilerTitle} onClick={(event) => handleSpoiler('spoilerThree', event)}>Есть ли скидки на уборки?</summary>
            {spoilerThree ?
              <div className={s.spoilerSubTitle}>Да, при первом заказе действует скидка по кодовому слову #ЧИСТОЧИСТО</div>
            : null}
          </details>
          <details className={s.spoiler} open={openSpoiler === 'spoilerFour'}>
            <summary className={s.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFour', event)}>Нужно ли что-то приготовить для клинера?</summary>
            {spoilerFour ?
              <div className={s.spoilerSubTitle}>Все моющие средства и инвентарь клинер привозит с собой, но с вашего позволения и при необходимости может воспользоваться Вашим пылесосом (в случае поддерживающей уборки).</div>
            : null}
          </details>
          <details className={s.spoiler} open={openSpoiler === 'spoilerFive'}>
            <summary className={s.spoilerTitle} onClick={(event) => handleSpoiler('spoilerFive', event)}>Что входит в уборку?</summary>
            {spoilerFive ?
              <div className={s.spoilerSubTitle}>В нижней части сайта расположена информация о составе услуг, при клике на соответствующую услугу, вы сможете ознакомится с информацией о составе услуг.</div>
            : null}
          </details>
        </div>
      </div>
      </div>
  )
}

export default Spoilers