import s from './Marketings.module.css'

const Marketings = () => {
  return (
    <div className={s.mobile}>
    <div className={s.marketingsContainer}>
      <div className={s.marketings}>
         <div className={s.container}>
            <div className={s.number}>7</div>
            <div className={s.title}>лет на <div>рынке</div></div>
          </div>
          <div className={s.container}>
            <div className={s.number}>1731</div>
            <div className={s.title}>довольных <div>клиента</div></div>
          </div>
          <div className={s.container}>
            <div className={s.number}>1,7</div>
            <div className={s.title}>млн. чистых <div>квадратных метров</div></div>
          </div>
      </div>
    
    </div>
    </div>
  )
}

export default Marketings