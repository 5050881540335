import {RadioButton} from "../../../components/RadioButton";
import SelectField from "../../../components/SelectField";
import {Option} from "@mui/base/Option";
import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    updateLabor
} from "../../../redux/reducers/calculator/calculatorSlice";
import CalculatorAdditionalsWidget from "./CalculatorAdditionalsWidget";
import CalculatorPromoWidget from "./CalculatorPromoWidget";
import CalculatorDateTimeWidget from "./CalculatorDateTimeWidget";
import CalculatorUsePointsWidget from "./CalculatorUsePointsWidget";
import {getAdditionals, getSubservicesByService, ICalcSubservice, RoomType} from "../../../api/calculator";
import {
    selectLaborById
} from "../../../redux/reducers/calculator/calculatorSelectors";
import {PropsWithAsAndClassName} from "../../../utils/props";
import useLaborContext from "../../../api/laborcontext/LaborContext";
import {DeviceMediaDetector} from "../../../utils/deviceDetection";


type CalculatorWidgetProps = PropsWithAsAndClassName & {
    simplified?: boolean
};

const squareValues = [30, 40, 50, 60, 70, 80, 90];

/**
 * @Composable
 * Виджет калькулятора
 * @constructor
 */
function CalculatorWidget(props: CalculatorWidgetProps = { as: "div" }) : React.JSX.Element {
    const dispatch = useAppDispatch();
    const { laborId } = useLaborContext();
    const labor = useAppSelector(selectLaborById(laborId));

    const handleSelectRoomChange = (event : React.MouseEvent<HTMLElement>)=> {
        dispatch(updateLabor({ id: laborId, room_type : event.currentTarget['value'] }))
    };

    const handleSquareChange = (event : React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>)=> {
        dispatch(updateLabor({ id: laborId, room_square : Number(event.currentTarget['value']) }))
    }

    const handleFocusSelectAll = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    }

    const particallyResetFields = () => {
        dispatch(updateLabor({ id: laborId, subservice : 'default' }))
        dispatch(updateLabor({ id: laborId, additional_list : [] }))
    }

    const subserviceOptions : React.JSX.Element = useMemo(() => {
        const subservice = getSubservicesByService(labor.room_type, labor.service);

        return (
            <>
                {
                    subservice.list.map((item: ICalcSubservice, index: number) => {
                        return (
                            <Option key={index} value={item.id}>
                                {item.text}
                            </Option>
                        )
                    })
                }
            </>
        )
    }, [labor.room_type, labor.service])

    return (
        <ScCalculatorWidget as={props.as} className={props.className}>
            <div className="calc__row calc__row--buttons calc__row--room">
                <RadioButton
                    selected={labor.room_type === RoomType.apartment}
                    onClick={(event) => {
                        particallyResetFields()
                        handleSelectRoomChange(event)
                    }}
                    value="apartment"
                >
                    Квартира
                </RadioButton>
                <RadioButton
                    selected={labor.room_type === RoomType.market}
                    onClick={(event) => {
                        particallyResetFields()
                        handleSelectRoomChange(event)
                    }}
                    value="market"
                >
                    Торговая точка
                </RadioButton>
                <RadioButton
                    selected={labor.room_type === RoomType.office}
                    onClick={(event) => {
                        particallyResetFields()
                        handleSelectRoomChange(event)
                    }}
                    value="office"
                >
                    Офис
                </RadioButton>
            </div>

            <span style={{margin:'41px 0 10px 0'}} className="calc__row--header">Площадь</span>
            <div className="calc__row calc__row--buttons calc__row--square">
                {
                    squareValues.map((item, index) => {
                        return (
                            <RadioButton key={item}
                                         selected={labor.room_square === item}
                                         onClick={handleSquareChange}
                                         value={item.toString()}
                            >
                                {item.toString()}
                            </RadioButton>
                        )
                    })
                }
                <RadioButton
                    selected={squareValues.indexOf(labor.room_square) === -1}
                    value={labor.room_square.toString()}
                >
                    <div className="calc__custom-square-input">
                        <input
                            type="number"
                            value={labor.room_square}
                            onChange={handleSquareChange}
                            onFocus={handleFocusSelectAll}
                        />
                        <span>м²</span>
                    </div>
                </RadioButton>
            </div>

            <span className="calc__row--header"></span>
            <div className="calc__row calc__row--services">
                <div className="services__work">
                    <span style={{position:'relative', top:'-10px'}}>Вид услуги </span>
                    
                    <SelectField  value={labor.service} onChange={(_, newValue) => {
                        particallyResetFields()
                        dispatch(updateLabor({ id: laborId, service : newValue }))
                    }}>
                        <Option value="support">
                            Поддерживающая уборка
                        </Option>
                        <Option value="general">
                            Генеральная уборка
                        </Option>
                        <Option value="build">
                            Послестроительная уборка
                        </Option>
                    </SelectField>
                </div>
                <div className="services__operation">
                    <span  style={{position:'relative', top:'-10px'}} >Пакет</span>
                    <SelectField value={labor.subservice} onChange={(_, newValue) => {
                        dispatch(updateLabor({ id: laborId, subservice : newValue }))
                    }}>
                        {subserviceOptions}
                    </SelectField>
                </div>
            </div>

            {
                !props.simplified && (
                    <>
                        <span className="calc__row--header">Дата/Время</span>
                        <div className="calc__row">
                            <CalculatorDateTimeWidget/>
                        </div>

                        {getAdditionals(labor).length > 0 && (<>
                            <span className="calc__row--header">Дополнительные услуги</span>
                            <div className="calc__row">
                            <CalculatorAdditionalsWidget/>
                            </div>
                        </>)}

                        <span className="calc__row--header">Промокод</span>
                        <div className="calc__row">
                            <CalculatorPromoWidget/>
                        </div>

                        <CalculatorUsePointsWidget/>
                    </>
                )
            }

        </ScCalculatorWidget>
    )
}

const ScCalculatorWidget = styled.div`
    display: flex;
    flex-direction: column;

    .calc__row {
        display: flex;

        * {
            font-size: inherit;
        }

        > * {
            flex: 1;
        }
    }

    .calc__row--header {
        margin-top: 2.5em;
    }

    .calc__row--buttons {
        > button {
            border-radius: 0;
            cursor: pointer;
        }

        > button:first-child {
            border-top-left-radius: ${props => props.theme.rounded.normal};
            border-bottom-left-radius: ${props => props.theme.rounded.normal};
        }

        > button:not(:first-child) {
            border-left: none;
        }

        > button:last-child {
            border-top-right-radius: ${props => props.theme.rounded.normal};
            border-bottom-right-radius: ${props => props.theme.rounded.normal};
        }
    }
    
    .calc__row--square {
        .calc__custom-square-input {
            display: flex;
            align-items: center;
            
            margin: 0 3px;
        }
        
        input[type="number"] {
            color: inherit;
            width: 45px;
            border: none;
            border-bottom: 1px solid ${props => props.theme.colors.secondary};
            
            background-color: transparent;
            
            &:focus {
                outline: none;
            }
        }

        .base--selected input[type="number"] {
            border-bottom: 1px solid ${props => props.theme.colors.surface};
        }
        
        /*disable arrows*/
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
    
    .calc__row--services {
        display: flex;
        column-gap: 10px;
        
        > * {
            flex: 1;
            text-align: left;
        }
        
        .services__work > *,
        .services__operation > * {
            width: 100%;
            min-height: 3em;
        }
    }
    
    @media (${DeviceMediaDetector.onWidth.desktop}) {
        .calc__row--services {
            flex-direction: column;
            row-gap: 30px;
        }
    }
`

export default CalculatorWidget;