import {preferDate, RoomType, ServiceType} from "../calculator";
import {getFakeShortPerson} from "../fakedata/persons";

export const LABOR_NEW_ID = "new";

/**
 * Статус заказа
 */
export enum LaborStatus {
    /**
     * Заказ создан
     */
    NEW = "NEW",
    /**
     * Клинер начал работы над заказом
     */
    WORKING = "WORKING",
    /**
     * Клинер закончил работы над заказом
     */
    CLEANER_CONFIRMED = "CLEANER_CONFIRMED",
    /**
     * Клиент подтвердил завершение работы над заказом
     */
    CLIENT_CONFIRMED = "CLIENT_CONFIRMED",
    /**
     * Заказ завершён
     */
    FINISHED = "FINISHED",
    /**
     * Заказ заморожен
     */
    FROZEN = "FROZEN",
    /**
     * Заказ отменён
     */
    CANCELED = "CANCELED"
}

/**
 * Интерфейс результата калькуляции
 */
export interface ICalculationResult {
    /**
     * Айди
     */
    id: string
    /**
     * Сумма основной части калькулятора
     */
    primarySum: number,
    /**
     * Сумма дополнительных услуг
     */
    additionalsSum: number,
    /**
     * Базовая сумма
     */
    mainSum: number,

    /**
     * Множитель промокода для базовой суммы
     */
    promoQuantifier: number,
    /**
     * Скалькулированная скидка промокода для базовой суммы
     */
    promoDiscountValue: number,

    /**
     * Количество бонусных баллов
     */
    pointsDiscountValue: number,
    /**
     * Максимальное доступное количество бонусных баллов для траты в этом заказе
     */
    maxAvailablePointsValue: number,
    /**
     * Итоговая сумма
     */
    totalSum: number
}

export const CalculationResultDefaults : ICalculationResult = {
    id: LABOR_NEW_ID,
    additionalsSum: 0,
    mainSum: 0,
    maxAvailablePointsValue : 0,
    pointsDiscountValue : 0,
    primarySum : 0,
    promoDiscountValue : 0,
    promoQuantifier : 0,
    totalSum : 0
}

export interface ILabor {
    // DATA
    id: string,
    room_type: RoomType,
    room_square: number,
    service: ServiceType,
    subservice: string,
    start_datetimeEpoch: number | null,
    end_datetimeEpoch: number,
    additional_list: string[],
    usedPoints: number,
    promoIdo: string | null,

    // PRESENTATION
    promoValue: string,
    promoQuantifier: number,

    // пока не используется
    address: string,
    personName: string,
    status: LaborStatus
}

export const LaborDefaults : ILabor = {
    // DATA
    id: LABOR_NEW_ID,
    room_type: RoomType.apartment,
    room_square: 30,
    service: ServiceType.general,
    subservice: "default",
    start_datetimeEpoch: preferDate(new Date()).getTime(),
    end_datetimeEpoch: null,
    additional_list: [],
    usedPoints: 0,
    promoIdo: null,

    // PRESENTATION
    promoValue: "",
    promoQuantifier: 1,

    address: "",
    // пока не используется
    personName: getFakeShortPerson(),
    status: LaborStatus.NEW
}


export type LaborDto = {
    ido: string
    start_datetime: string
    end_datetime: string | null
    room_square: number
    room_type: string
    service: string
    subservice: string
    additional_list: string[]
    promo_ido: string | null,
    used_points: number,

    fias_address: string
}

export function mapLaborToDto(source: ILabor) : LaborDto {
    return {
        ido: source.id,
        start_datetime: new Date(source.start_datetimeEpoch).toISOString(),
        end_datetime: source.end_datetimeEpoch === null ? null : new Date(source.end_datetimeEpoch).toISOString(),

        room_square: source.room_square,
        room_type: source.room_type.toLowerCase(),
        service: source.service.toLowerCase(),
        subservice: source.subservice.toLowerCase(),
        additional_list: source.additional_list,
        promo_ido: source.promoIdo,
        used_points: source.usedPoints,

        fias_address: source.address
    }
}

export function mapLaborFromDto(source: LaborDto) : ILabor {
    return {
        id: source.ido,
        start_datetimeEpoch: new Date(source.start_datetime || new Date().getTime() ).getTime(),
        end_datetimeEpoch: source.end_datetime === null ? null : new Date(source.end_datetime).getTime(),

        room_square: source.room_square,
        room_type: RoomType[source.room_type.toLowerCase()],
        service: ServiceType[source.service.toLowerCase()],
        subservice: source.subservice.toLowerCase(),
        additional_list: source.additional_list,
        usedPoints: 0,
        promoIdo: source.promo_ido,

        promoValue: source.promo_ido,
        promoQuantifier: 1,

        address: source.fias_address,
        personName: getFakeShortPerson(),
        status: LaborStatus.NEW
    }
}