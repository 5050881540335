import s from './Previews.module.css'
import { ReactComponent as Frame } from '../../images/Oval 1.svg';
import { ReactComponent as Logo }  from '../../images/logo2.svg';
const Previews = () => {
  return (
    <div className={s.previewsContainer}>
    <div className={s.previews}>
      <div className={s.logoContainer}>
       
        <div className={s.logoTitle}>Обслуживание
        <div className={s.logo}> <Logo className={s.logo}  /></div> 
          
           которого вы заслуживаете!</div>
      </div>
      <div className={s.titleContainer}>

       <div className={s.title}>
          <Frame />
          <div className={s.clean}>Обученные клинеры</div>
        </div>

        <div className={s.title2}>
          <Frame className={s.frame} />
          <div className={s.clean}>Профессиональное <div>оборудование</div></div>
        </div>

        <div className={s.title2}>
          <Frame className={s.frame} />
          <div className={s.clean}>Безопасные<div> моющие средства</div></div>
        </div>
        
        <div className={s.title2}>
          <Frame className={s.frame} />
          <div className={s.clean}>Высокие стандарты <div>обслуживания</div></div>
        </div>
      </div>
       </div>
      </div>
  )
}

export default Previews