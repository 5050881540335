import s from './MainPage.module.css'
import Header from '../../../components/Header/Header';
import Sliders from '../../../components/Sliders/Sliders';
import CleanCards from '../../../components/CleanCards/CleanCards';
import Calculates from '../../../components/Calculates/Calculates';
import Previews from '../../../components/Previews/Previews';
import Marketings from '../../../components/Marketings/Marketings';
import FormOrders from '../../../components/FormOrders/FormOrders';
import Spoilers from '../../../components/Spoilers/Spoilers';
import Footer from '../../../components/Footer/Footer';

/**
 * @function {MainPage} - Компонент заглавной страницы для приложения "Клининг", десктопная версия.
 * @returns {React.JSX.Element} - JSX-элемент, представляющий заглавную страницу.
 */

function MainPage(): React.JSX.Element {
 
  return (
    <div  className={s.containerMain}>
      <Header/>
      <Sliders />
      <CleanCards/>
      <Calculates/>
      <Previews/>
      <Marketings/>
      <FormOrders/>
      <Spoilers/>
      <Footer/>
    </div>
  );
};

export default MainPage;
