import React, { useState } from 'react';
import style from './PhoneInput.module.css'
import { useForm } from 'react-hook-form';
import Axios from 'axios'

const PhoneInput: React.FC = () => {
    const [mask, setMask] = useState("+7 900 000 00 00");
    const [cursorPosition, setCursorPosition] = useState(5);
    const [check, setCheck] = useState(false);
    const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });
    const [label, setLabel] = useState(false);
    type TFormValues = {
        phone: string;
        name: string;
        type: string;
      };
      const api = `https://402.m-dels.ru/order`;
      
    
      const onSubmit = async (formData: TFormValues) => {
          if (!watch('name') || !watch('phone') || watch('phone').length !== 9) {
            return null;
          } else if(!check) {
            return null;
          } else {
            try {
              const dataToSend = {
                'order-info': {
                  phone: formData.phone = mask,
                  name: formData.name,
                  type: formData.type || '',
                }
              };
        
              await Axios.post(api, dataToSend)
                .then((response) => {
                  console.log(response);
                });
            } catch (error) {
              console.log('Error:', error);
            }
          }
        };
   // функция для ввода телефона
   const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    let result = event.target.value.replace(/\D/g, '');
    if (result.length > 0) {
      setMask(`+7 9${result[0]}0 000 00 00`);
      setCursorPosition(5.8);
    }
    if (result.length > 1) {
      setMask(`+7 9${result.slice(0, 2)} 000 00 00`);
      setCursorPosition(6.7);
    }
    if (result.length > 2) {
      setMask(`+7 9${result.slice(0, 2)} ${result[2]}00 00 00`);
      setCursorPosition(8);
    }
    if (result.length > 3) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 4)}0 00 00`);
      setCursorPosition(9);
    }
    if (result.length > 4) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 5)} 00 00`);
      setCursorPosition(9.8);
    }
    if (result.length > 5) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 5)} ${result[5]}0 00`);
      setCursorPosition(11.1);
    }
    if (result.length > 6) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 5)} ${result.slice(5, 7)} 00`);
      setCursorPosition(12);
    }
    if (result.length > 7) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 5)} ${result.slice(5, 7)} ${result[7]}0`);
      setCursorPosition(13.3);
    }
    if (result.length > 8) {
      setMask(`+7 9${result.slice(0, 2)} ${result.slice(2, 5)} ${result.slice(5, 7)} ${result.slice(7, 9)}`);
      setCursorPosition(10000);
    }
    if (result.length === 0) {
      setMask(`+7 900 000 00 00`);
      setCursorPosition(5.2);
    }

    event.target.value = result.slice(0, 9);
    
    setValue('phone', result.slice(0, 9));
  };
  return (
    <label 
    className={style.phone} 
    htmlFor="phone" 
    onClick={(e: React.MouseEvent<HTMLLabelElement>) =>{
      e.stopPropagation();
      setLabel(true);
    }}
  >
    {mask}
    <input
      id="phones"
      className={style.phones}
      type="text"
      {...register('phone', {onChange: handlePhone})}
    />
    <span className={label ? style.cursor : style.cursorHidden} style={{ left: `${cursorPosition * 10}px` }} />
  </label>
  );
};

export default PhoneInput;