import {createEntityAdapter, createSlice, EntityState, PayloadAction} from '@reduxjs/toolkit'
import {PartialButIdRequired} from "../../../api/model/reflex";
import {
    CalculationResultDefaults,
    ICalculationResult,
    ILabor,
    LABOR_NEW_ID,
    LaborDefaults
} from "../../../api/model/labor";
import calculate from "../../../api/calculator";
import {getFakeLabor, getFakeLaborId} from "../../../api/fakedata/labors";

export const laborAdapter = createEntityAdapter({
    selectId: (labor: ILabor) => labor.id,
    sortComparer: (a, b) => a.start_datetimeEpoch - b.start_datetimeEpoch,
})

export const calculationResultAdapter = createEntityAdapter({
    selectId: (calculationResult: ICalculationResult) => calculationResult.id
})

interface ICalculatorSliceState {
    labors: EntityState<ILabor, string>
    calculationResults: EntityState<ICalculationResult, string>,
    openedLaborIds: string[]
}

const initialState: ICalculatorSliceState = {
    labors: laborAdapter.getInitialState({}, [
        LaborDefaults,

        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor(),
        getFakeLabor()
    ]),
    calculationResults: calculationResultAdapter.getInitialState({}, [CalculationResultDefaults]),
    openedLaborIds: []
}

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    reducers: {
        openLaborInTab: (state, action: PayloadAction<string>) => {
            if (state.openedLaborIds.indexOf(action.payload) !== -1) {
                return;
            }

            state.openedLaborIds.push(action.payload);

            recalculateLaborById(state, action.payload);
        },
        closeLaborInTab: (state, action: PayloadAction<string>) => {
            const index = state.openedLaborIds.indexOf(action.payload)
            if (index > -1) {
                state.openedLaborIds.splice(index, 1)
            }
        },

        createLaborFromNew: (state) => {
            const newLaborId = getFakeLaborId();
            const shallowCopy = {...state.labors.entities[LABOR_NEW_ID], id: newLaborId};

            laborAdapter.addOne(state.labors, shallowCopy)

            recalculateLaborById(state, newLaborId);
        },
        updateLabor: (state, action: PayloadAction<PartialButIdRequired<ILabor>>) => {
            laborAdapter.updateOne(state.labors, { id: action.payload.id, changes: action.payload })

            recalculateLaborById(state, action.payload.id);
        },
        updateLaborCheckAdditional: (state, action: PayloadAction<PartialButIdRequired<{ value: string }>>) => {
            const prevState = state.labors.entities[action.payload.id]
            laborAdapter.updateOne(state.labors, { id: action.payload.id, changes: { additional_list: [ ...prevState.additional_list, action.payload.value ] } })

            recalculateLaborById(state, action.payload.id);
        },
        updateLaborUncheckAdditional: (state, action: PayloadAction<PartialButIdRequired<{ value: string }>>) => {
            const prevState = state.labors.entities[action.payload.id]
            laborAdapter.updateOne(state.labors, { id: action.payload.id, changes: { additional_list: [ ...prevState.additional_list.filter(element => element !== action.payload.value) ] } })

            recalculateLaborById(state, action.payload.id);
        }
    },
})

function recalculateLaborById(state: ICalculatorSliceState, id: string) {
    calculationResultAdapter.setOne(state.calculationResults, calculate(state.labors.entities[id]));
}

// Action creators are generated for each case reducer function
export const { openLaborInTab, closeLaborInTab, createLaborFromNew, updateLabor, updateLaborCheckAdditional, updateLaborUncheckAdditional } = calculatorSlice.actions;
export default calculatorSlice.reducer;