import React, {useEffect, useMemo} from "react";
import {getAdditionals, ICalcBase} from "../../../api/calculator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    updateLabor, updateLaborCheckAdditional, updateLaborUncheckAdditional,
} from "../../../redux/reducers/calculator/calculatorSlice";
import styled from "styled-components";
import {selectLaborById} from "../../../redux/reducers/calculator/calculatorSelectors";
import useLaborContext from "../../../api/laborcontext/LaborContext";


/**
 * @Composable
 * Виджет дополнительных услуг калькулятора
 * @constructor
 */
function CalculatorAdditionalsWidget() : React.JSX.Element {
    const dispatch = useAppDispatch();
    const { laborId } = useLaborContext();
    const labor = useAppSelector(selectLaborById(laborId));

    const handleAdditionalCheckbox = (event: React.ChangeEvent<HTMLInputElement>, item: ICalcBase) => {
        if (event.target.checked) {
            dispatch(updateLaborCheckAdditional({ id: laborId, value: item.id} ));
        } else {
            dispatch(updateLaborUncheckAdditional({ id: laborId, value: item.id} ))
        }
    }

    useEffect(() => {
        dispatch(updateLabor({ id: laborId, additional_list: [] }))
    }, [labor.room_type, labor.service, labor.subservice])

    return useMemo(() => {
        const list = getAdditionals(labor);

        return (
            <ScCalculatorAdditionalsWidget>
                {
                    list.map((item, index) => {
                        return (
                            <span key={index}>
                                <input type="checkbox"
                                       data-id={item.id}
                                       checked={labor.additional_list.includes(item.id)}
                                       onChange={(e) => handleAdditionalCheckbox(e, item)}
                                       id={"ScCalculatorAdditionalsWidget_" + index}
                                />
                                <label htmlFor={"ScCalculatorAdditionalsWidget_" + index}>{item.text}</label>
                            </span>
                        )
                    })
                }
            </ScCalculatorAdditionalsWidget>
        )
    }, [labor.room_type, labor.service, labor.subservice, labor.additional_list]);
}

const ScCalculatorAdditionalsWidget = styled.div`
    display: flex;
    flex-direction: column;
`

export default CalculatorAdditionalsWidget;