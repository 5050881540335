import {getFakeStreet} from "./streets";
import {getFakeShortPerson} from "./persons";
import {ILabor, LaborStatus} from "../model/labor";
import {RoomType, ServiceType} from "../calculator";

const laborStatuses = [
    LaborStatus.NEW,
    LaborStatus.WORKING,
    LaborStatus.CLEANER_CONFIRMED,
    LaborStatus.FROZEN,
    LaborStatus.CLIENT_CONFIRMED
]

const serviceTypes = [
    ServiceType.general,
    ServiceType.build,
    ServiceType.support
]

export const getFakeLaborId = () : string => {
    return Math.floor(Math.random() * 1000000).toString();
}

export const getFakeLabor = () : ILabor => {
    const fakeId = getFakeLaborId()

    return {
        id: fakeId,

        promoIdo: null,
        room_type: RoomType.apartment,
        room_square: Math.floor(Math.random() * 70 + 30),
        service: serviceTypes[Math.floor(Math.random() * serviceTypes.length)],
        subservice: "default",
        start_datetimeEpoch: new Date(2024, 9, Math.random() * 10 + 10, Math.random() * 8 + 8, Math.random() * 60).getTime(),
        end_datetimeEpoch: new Date(2024, 9, Math.random() * 10 + 10, Math.random() * 8 + 8, Math.random() * 60).getTime(),
        additional_list: [],
        promoValue: fakeId,
        promoQuantifier: 1,
        usedPoints: 0,

        address: getFakeStreet(),
        personName: getFakeShortPerson(),

        status: laborStatuses[Math.floor(Math.random() * laborStatuses.length)]
    }
}