import {useState, useEffect} from "react";
import { ReactComponent as Logo }  from '../../images/logo2.svg';
import { ReactComponent as Phone } from '../../images/phone.svg';
import { ReactComponent as LogIn } from '../../images/logIn.svg';
import { SpisokModal } from '../../pages/main/MainPage/SpisokModal';
import { PhoneModal } from '../../pages/main/MainPage/PhoneModal';
import { ReactComponent as Menu } from '../../images/menu.svg';
import s from './Header.module.css'
import BurgerMenu from "./BurgerMenuMobile/BurgerMenu";
const Header = () => {
    const [openPhone, setOpenPhone] = useState(false);
    const [scrolls, setScrolls] = useState(0);
    const [openSpisok, setOpenSpisok] = useState(false);
    const [openSpisokId, setOpenSpisokId] = useState('');
    const [cleaner, setCleaner] = useState(false);
  
    
  // открываем модалку для звонка
  const handlePhones = () => {
    setOpenPhone(true);
    setCleaner(false);
  };
    // открываем модалку для клинера
    const handleCleaner = () => {
        setOpenPhone(true);
        setCleaner(true);
      };
    

  // функция для закрытия модального окна отображающего услуги, оферту и политику соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokId('')
  }
  // считываем скролл по оси Y

    useEffect(() => {
       
        const handleScroll = () => {
          setScrolls(window.scrollY);
        };
    
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll',handleScroll);
        };
      }, []);

    return (
      <div className={s.Mobile}>
      <div className={scrolls > 0 ? s.headerContainerScrolled : s.headerContainer}>
        <div className={scrolls > 0 ? s.headerScrolled : s.header}>
          <div className={s.burger}>
              <BurgerMenu/>
          </div>
          <div className={s.logo}>
              <Logo className={scrolls > 0 ? s.logoScrolled : s.logo} />
          </div>
        <div className={s.contactsContainer}>
          <div className={s.contacts}>
            <Phone className={scrolls > 0 ? s.phoneScrolled : s.phone} />
            <div className={scrolls > 0 ? s.phoneNumberScrolled : s.phoneNumber}>8 (8182) 43-32-10</div>
            <button className={scrolls > 0 ? s.buttonCallScrolled : s.buttonCall}
            type="button" 
            onClick={() => setOpenPhone(true)}
            >Заказать звонок</button>
          </div>
          <button className={s.btns} type="button">
            <LogIn className={scrolls > 0 ? s.logInScrolled : s.logIn} />
          </button>
        </div>
        <SpisokModal 
        open={openSpisok}
        modal_id={openSpisokId}
        onClose={handleSpisokClose} 
      />
      <PhoneModal open={openPhone} onClose={() => setOpenPhone(false)} cleaner={cleaner} />
        </div>
        
      </div>
      </div>
    )
  }


  export default Header