import {createApi} from "@reduxjs/toolkit/query/react";
import {buildBaseQueryWithAxiosInstance} from "../../baseQueryBuilder";
import {apiClient} from "../../../api/auth";
import {ILabor, LaborDto, mapLaborFromDto, mapLaborToDto} from "../../../api/model/labor";


const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: buildBaseQueryWithAxiosInstance({ axiosInstance : apiClient, urlPrefix: '' }),
    tagTypes: ['Order', 'Wallet'],
    endpoints: (builder) => ({
        getOrders: builder.query<ILabor[], void>({
            query: () => ({ url: '/order', method: 'get' }),
            providesTags: ['Order'],
            transformResponse: (data: LaborDto[]) => data.map(mapLaborFromDto)
        }),
        getOrder: builder.query<ILabor, string>({
            query: (arg) => ({ url: '/order/' + arg, method: 'get' }),
            providesTags: (data, error, arg) => [{ type: 'Order', id: arg }]
        }),

        postOrder: builder.mutation<ILabor, ILabor>({
            query: (arg) => ({ url: '/order', method: 'post', data: mapLaborToDto(arg) }),
            invalidatesTags: ['Order', 'Wallet'],
            transformResponse: (data: LaborDto) => mapLaborFromDto(data)
        })
    })
})

export const {
    useGetOrdersQuery, useLazyGetOrdersQuery,
    usePostOrderMutation,
} = orderApi;
export default orderApi;