import s from './Footer.module.css';
import { ReactComponent as Logo } from '../../images/logo2.svg';
import { useState } from 'react';
import { SpisokModal } from '../../pages/main/MainPage/SpisokModal';
import { PhoneModal } from '../../pages/main/MainPage/PhoneModal';
const Footer = () => {
    const [openSpisok, setOpenSpisok] = useState(false);
    const [openSpisokId, setOpenSpisokId] = useState('');
    const [cleaner, setCleaner] = useState(false);
    const [openPhone, setOpenPhone] = useState(false);
    
  // открываем модалку для звонка
  const handlePhones = () => {
    setOpenPhone(true);
    setCleaner(false);
  };
    // открываем модалку для клинера
    const handleCleaner = () => {
        setOpenPhone(true);
        setCleaner(true);
      };
    

  // функция для закрытия модального окна отображающего услуги, оферту и политику соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokId('')
  }
  return (

   <div id='footer' className={s.footerContainerMobile}>
    <div className={s.footerContainer}>
      <div className={s.firstItems}>
          <div className={s.logoContainer}>
              <div className={s.logo}>
                <Logo className={s.logo}/>
              </div>
              <div className={s.phoneNum}>Тел: 8 (8182) 43-32-10</div>
              <div>
                <button type="button" 
                        className={s.buttonCall} 
                        onClick={handlePhones}>
                          Заказать звонок
                </button>
              </div>
          </div>
          <div style={{display:'flex'}}>
          <div className={s.Lastik}>
            <div className={s.lastik}>Lastik</div>
              <div className={s.lastikTitle}>
                <div><div className={s.help}>Помощь</div><br/><div className={s.help} onClick={handleCleaner}>Стать клинером</div></div>
              </div>
          </div>
          <div >
            <div className={s.clean}>Уборка</div>
            <div className={s.cleanTitle}>
              <div >
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('support_default')}}>Поддерживающая-стандарт</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('support_light')}}>Поддерживающая-лайт</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('support_premium')}}>Поддерживающая-премиум</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('general_default')}}>Генеральная-стандарт</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('general_assoc')}}>Генеральная-ассоциальная</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('general_party')}}>Генеральная-после вечеринки</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('general_presold')}}>Генеральная-предпродажная</div><br />
                <div className={s.help} onClick={() => {setOpenSpisok(true); setOpenSpisokId('build_default')}}>Послестроительная-стандарт</div>
              </div>
            </div>
          </div>
          </div>
      </div>
      <div className={s.secondItems}>
          <div>
            <div className={s.contract}>
              <span>Используя информационный ресурс https://lastik.vip Вы соглашаетесь с условиями <span className={s.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('offer')}}>Оферты</span> и соглашаетесь с <span className={s.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('politic')}}>Политикой конфиденциальности</span></span>
            </div>
          </div>
          <div className={s.contract2}>© 2024, ООО «КАКТУС»</div>
      </div>
      <SpisokModal 
        open={openSpisok}
        modal_id={openSpisokId}
        onClose={handleSpisokClose} 
      />
      <PhoneModal open={openPhone} onClose={() => setOpenPhone(false)} cleaner={cleaner} />
        
</div>
   </div>

  )
}

export default Footer