import React from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    updateLabor,
} from "../../../redux/reducers/calculator/calculatorSlice";
import ScInput from "../../../components/styled/ScInput";
import {ScOutlinedIconButton} from "../../../components/styled/ScButton";
import CheckmarkIcon from "../../../components/icons/CheckmarkIcon";
import styled from "styled-components";
import {selectLaborById} from "../../../redux/reducers/calculator/calculatorSelectors";
import useLaborContext from "../../../api/laborcontext/LaborContext";
import {PromoDto} from "../../../api/model/promo";
import {useCheckPromoByValueMutation} from "../../../redux/reducers/order/othersEndpoints";


/**
 * @Composable
 * Виджет промокода калькулятора
 * @constructor
 */
function CalculatorPromoWidget() : React.JSX.Element {
    const dispatch = useAppDispatch();
    const [checkPromo, {
        data: promoData,
        isSuccess: promoIsSuccess,
        isLoading: promoIsLoading,
        isUninitialized: promoIsUninitialized
    }] = useCheckPromoByValueMutation()

    const { laborId } = useLaborContext();
    const labor = useAppSelector(selectLaborById(laborId));

    const handleButtonSubmit = async () => {
        if (labor.promoValue.length > 2) {
            const response = await checkPromo(labor.promoValue)
            const data = response['data'] as PromoDto
            if (data) {
                dispatch(updateLabor({ id: laborId, promoIdo: data.ido, promoQuantifier: data.quantifier }));
            }
            else {
                dispatch(updateLabor({ id: laborId, promoIdo: null }));
            }
        }
    }

    const promoComment = (promoIsUninitialized: boolean, promoIsLoading: boolean, promoIsSuccess: boolean) => {
        if (promoIsUninitialized) {
            return ''
        }
        else if (promoIsLoading) {
            return 'Загрузка'
        }
        else if (promoIsSuccess) {
            return `Применен промокод на ${100 - promoData!!.quantifier * 100}%`
        }
        else {
            return 'Промокод не найден'
        }
    }

    return (
        <ScCalculatorPromoWidget>
            <div>
                <ScInput value={labor.promoValue} onChange={(e) => {
                    dispatch(updateLabor({ id: laborId, promoValue: e.target.value }));
                }}/>
                <ScOutlinedIconButton onClick={handleButtonSubmit}><CheckmarkIcon/></ScOutlinedIconButton>
            </div>

            <span className="calc__row--promo_comment">{promoComment(promoIsUninitialized, promoIsLoading, promoIsSuccess)}
            </span>
        </ScCalculatorPromoWidget>
    )
}


const ScCalculatorPromoWidget = styled.div`
    display: block;

    > div {
        display: flex;
        
        column-gap: 10px;
        
        ${ScInput} {
            flex: 1;
        }
    }

`

export default CalculatorPromoWidget;