export enum ServiceEnum {
    BANAN
}

const ServiceEnumDefault = ServiceEnum.BANAN;

/**
 * Интерфейс информации о сервисе
 */
interface IService {
    baseUrl: string;
    baseUrlDevelop: string;
}

/**
 * Словарь содержащий информацию об всех сервисах
 */
const servicesDataDict : Record<ServiceEnum, IService> = {
    [ServiceEnum.BANAN] : {
        baseUrl: "https://402.m-dels.ru",
        baseUrlDevelop: "http://127.0.0.1:8000"
    }
}

/**
 * Класс конфигурации приложения
 */
class Configurator {
    /**
     * Если приложение находится в режиме разработки
     * @returns {boolean}
     */
    public IsDevelopmentMode() : boolean {
        return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    }

    /**
     * Получить объект сервиса по его enum
     * @param {ServiceEnum} service
     * @returns {IService}
     */
    private resolveService(service: ServiceEnum): IService {
        return servicesDataDict[service];
    }

    /**
     * Получить URL инстанса сервиса
     * @param {ServiceEnum} service
     * @returns {string}
     */
    public ResolveServiceUrl(service: ServiceEnum = ServiceEnumDefault) : string {
        return this.IsDevelopmentMode() ?
            this.resolveService(service).baseUrlDevelop :
            this.resolveService(service).baseUrl;
    }
}

const configurator = new Configurator();

console.log(configurator.ResolveServiceUrl())

export default configurator;