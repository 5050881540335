import {RootState} from "../../store";
import {createDraftSafeSelectorCreator, createSelector, weakMapMemoize} from "@reduxjs/toolkit";
import {calculationResultAdapter, laborAdapter} from "./calculatorSlice";
import {LABOR_NEW_ID} from "../../../api/model/labor";
import walletEndpoints from "../order/walletEndpoints";

const createWeakMapDraftSafeSelector =
    createDraftSafeSelectorCreator(weakMapMemoize)

const selectCalculatorReducer = (state: RootState) => state.calculatorReducer;
const selectCalculatorLabors = (state: RootState) => state.calculatorReducer.labors;
const selectCalculationResults = (state: RootState) => state.calculatorReducer.calculationResults;
export const selectWallet = (state: RootState) => walletEndpoints.endpoints.getWallet.select()(state).data ?? { points_amount: 0, balance_amount: 0 }

const {
    selectById: laborSelectById,
    selectAll: laborSelectAll,
} = laborAdapter.getSelectors(undefined, {
    createSelector: createWeakMapDraftSafeSelector
})

const {
    selectById: calculationResultSelectById,
} = calculationResultAdapter.getSelectors(undefined, {
    createSelector: createWeakMapDraftSafeSelector
})


/**
 * @Selector
 * Выбрать максимальное количество доступных для использования баллов с учётом:
 * - выбранной конфигурации заказа
 * - доступного у пользователя в кошельке баллов
 */
export const selectCalculatorMaxAvailablePointsValue = (id: string) => createSelector(
    [selectCalculationResults, selectWallet],
    (calculationResults, walletDto) => Math.min(
        calculationResultSelectById(calculationResults, id).maxAvailablePointsValue,
        walletDto.points_amount
    )
)

/**
 * @Selector
 * Выбрать результат калькуляций.
 * @remarks Более глубокая выборка не даст оптимизации, так как объект обновляется целиком (поля зависимы друг от друга)
 */
export const selectCalculationResult = (id: string) => createSelector(
    [selectCalculationResults],
    (calculationResults) => calculationResultSelectById(calculationResults, id)
)


/**
 * @Selector
 * Получить результат калькуляций по id 'new'
 */
export const selectCalculationResultByIdNew = selectCalculationResult(LABOR_NEW_ID);


/**
 * @Selector
 * Выбрать заказ по айди
 */
export const selectLaborById = (id: string) => createSelector(
    [selectCalculatorLabors],
    (calculatorLabors) => laborSelectById(calculatorLabors, id)
)


/**
 * @Selector
 * Выбрать заказ по айди 'new'
 */
export const selectLaborByIdNew = selectLaborById(LABOR_NEW_ID)


/**
 * @Selector
 * Выбрать все заказы
 */
export const selectLabors = createSelector(
    [selectCalculatorLabors],
    (calculatorLabors) => laborSelectAll(calculatorLabors).filter((labor) => labor.id !== LABOR_NEW_ID)
)

/**
 * @Selector
 * Получить айди редактируемых/просматриваемых в данный момент заказов во вкладках
 */
export const selectOpenedLaborIds = createSelector(
    [(state: RootState) => state.calculatorReducer.openedLaborIds],
    (openedLaborIds) => openedLaborIds
)