import orderApi from "./orderApi";
import {PromoDto} from "../../../api/model/promo";
import StatsProfileDto from "../../../api/model/stats";


const othersEndpoints = orderApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrderStats: builder.query<StatsProfileDto, void>({
            query: () => ({
                url: '/stats',
                method: 'get'
            }),
            providesTags: ['Order']
        }),

        checkPromoByValue: builder.mutation<PromoDto, string>({
            query: (arg) => ({
                url: '/order/promo/' + arg,
                method: 'get'
            })
        })
    })
})

export const {
    useGetOrderStatsQuery, useLazyGetOrderStatsQuery,
    useCheckPromoByValueMutation,
} = othersEndpoints
export default othersEndpoints