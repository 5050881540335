import {createApi} from "@reduxjs/toolkit/query/react";
import {buildBaseQueryWithAxiosInstance} from "../../baseQueryBuilder";
import {apiClient} from "../../../api/auth";
import {IndividualUserDto} from "../../../api/model/baseUser";


const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: buildBaseQueryWithAxiosInstance({ axiosInstance : apiClient, urlPrefix : '' }),
    tagTypes: ['Profile', 'Address'],
    endpoints: (builder) => ({
        signUp: builder.mutation<void, IndividualUserDto>({
            query: (arg) => ({
                url: '/signup/' + arg.role,
                method: 'get',
                data: arg
            })
        }),

        getProfile: builder.query<IndividualUserDto, void>({
            query: () => ({
                url: '/profile',
                method: 'get'
            }),
            providesTags: ['Profile']
        }),

        updateProfile: builder.mutation<IndividualUserDto, IndividualUserDto>({
            query: (arg) => ({
                url: '/profile',
                method: 'put',
                data: arg
            }),
            invalidatesTags: ['Profile']
        }),

        uploadAvatar: builder.mutation<void, File>({
            query: (arg) => {
                const fd = new FormData();
                fd.set('file', arg)

                return ({
                    url: '/profile/avatar',
                    method: 'post',
                    data: fd
                })
            },
            invalidatesTags: ['Profile']
        })
    })
})

export const {
    useSignUpMutation,
    useGetProfileQuery, useLazyGetProfileQuery,
    useUpdateProfileMutation,
    useUploadAvatarMutation
} = profileApi;
export default profileApi;