
import { useMediaQuery } from 'react-responsive';
import FirstCard from './Card/FirstCard';
import SecondCard from './Card/SecondCard';
import ThirdCard from './Card/ThirdCard';
import CardsMobile from './CardsMobile/CardsMobile';

const CleanCards = () => {
    
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

 return (
  
      <div >
        {isDesktopOrLaptop ? (<div id='cards' style={{display:'flex', width:'1440px'}}>
          <FirstCard /> <SecondCard /> <ThirdCard />
         
      </div>
       
      ) : isTabletOrMobile ? (
        <div id='cards'>
            <CardsMobile/>
        </div>
       
      ) : null}
    
    </div>
 )
}

export default CleanCards;