import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loadingReducer from './reducers/loading';

import flagReducer from './reducers/mainPage/flagSlice';
import calculatorReducer from './reducers/calculator/calculatorSlice';
import zulipReducer from './reducers/zulip/zulipSlice';
import popupReducer from "./reducers/popup/popupSlice";
import orderApi from "./reducers/order/orderApi";
import profileApi from "./reducers/profile/profileApi";
import orderReducer from './reducers/order/orderSlice';

const multipleReducer = combineReducers({
  loadingReducer,
  flagReducer,
  calculatorReducer,
  zulipReducer,
  popupReducer,
  [orderApi.reducerPath] : orderApi.reducer,
  [profileApi.reducerPath] : profileApi.reducer,
  orderReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: multipleReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat([
                orderApi.middleware,
              profileApi.middleware
            ])
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
const store = setupStore();

export default store;
