import s from './Calculates.module.css';
import { ReactComponent as Wapp } from '../../images/Wapp.svg';
import { ReactComponent as Tg } from '../../images/Tgs.svg';
import CalculatorWidget from "../../pages/widgets/calculator/CalculatorWidget";
import LaborContextProvider from "../../api/laborcontext/LaborContextProvider";
import {ScTextButton} from "../../components/styled/ScButton";
import { useState } from 'react';
import { SpisokModal } from '../../pages/main/MainPage/SpisokModal';
import { useAppSelector} from '../../hooks/redux';
import {selectCalculationResult, selectLaborById} from "../../redux/reducers/calculator/calculatorSelectors";
import {LABOR_NEW_ID} from "../../api/model/labor";
import { ServiceCalckModal } from '../../pages/main/MainPage/ServiceCalckModal';
import {getLocalRoomType, getLocalService, getLocalSubservice} from "../../api/calculator";
const Calculates = () => {
    const [openSpisokId, setOpenSpisokId] = useState('');
  const [openSpisok, setOpenSpisok] = useState(false);

  const [openCalculate, setOpenCalculate] = useState(false);

  const selectCalculationResultByIdNew = selectCalculationResult(LABOR_NEW_ID);
  const selectLaborByIdNew = selectLaborById(LABOR_NEW_ID)

  const calculationResult = useAppSelector(selectCalculationResultByIdNew);
  const labor = useAppSelector(selectLaborByIdNew);
  // функция для закрытия модального окна отображающего услуги, оферту и политику соглашения
  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokId('')
  }

//watsapp
const handleWa = () => {
    const url = "https://api.whatsapp.com/message/232ZXS3OASO5P1?autoload=1&app_absent=0";
    window.open(url, '_blank'); 
  };
  
// telegram
  const handleTg = () => {
    const url = "https://t.me/LaStik444";
    window.open(url, '_blank'); 
  };


  return (
    <div id='calculates' className={s.Mobile}>
      <div className={s.container}>
        <div className={s.first}>
          <div className={s.logo}>Подбери уборку для себя</div>
          <div className={s.title}>Не удалось подобрать, мы обязательно поможем</div>
          <div className={s.socContainer}>
            <button className={s.watsapp} onClick={handleWa}>
              <Wapp className={s.wapp}/>
            </button>
            <button className={s.telegram} onClick={handleTg}>
              <Tg className={s.tg}/>
            </button>
          </div>
        </div>
        <div className={s.second}>
          <div className={s.calculator}>
            <LaborContextProvider laborId={'new'}>
              <CalculatorWidget simplified={true}/>
            </LaborContextProvider>
            
          </div>
          <div className={s.subTitle}> 
            <ScTextButton
              onClick={() => {
              setOpenSpisok(true);
              setOpenSpisokId(labor.service + '_' + labor.subservice);
              }}>Состав услуг
            </ScTextButton>
          </div>
          <div className={s.sumContainer}>
            <div className={s.sumTitle}>Цена</div>
            <div className={s.sum}>от {calculationResult.primarySum.toLocaleString('ru-RU')} ₽</div>
          </div>
          <div>
            <button className={s.order} onClick={() => setOpenCalculate(true)}>
              Заказать
            </button>
          </div>
        </div>
      </div>
      <ServiceCalckModal
        open={openCalculate}
        onClose={() => setOpenCalculate(false)}
        house={getLocalRoomType(labor)}
        square={labor.room_square.toString()}
        serviceType={getLocalService(labor) + ' ' + getLocalSubservice(labor)}
      />
    <SpisokModal 
        open={openSpisok}
        modal_id={openSpisokId}
        onClose={handleSpisokClose} 
      />
    </div>
  )
}

export default Calculates