// Перечисление помещение
import {ICalculationResult, ILabor} from "./model/labor";

export enum RoomType {
    office      = "office",     // Офис
    apartment   = "apartment",  // Квартира
    market      = "market"      // Торговая точка
}

// Перечисление услуга
export enum ServiceType {
    support     = "support",    // поддерживающая
    general     = "general",    // генеральная
    build       = "build"       // послестроительная
}

export interface ICalcIdentificatable {
    id?: string,
    text: string
}


export interface ICalcBase extends ICalcIdentificatable {
    fun: (squareValue: number) => number
}

export interface ICalcSubservice extends ICalcBase {
    additional?: ICalcBase[]
}

export interface ICalcService extends ICalcIdentificatable {
    list: ICalcSubservice[]
}

export interface ICalcRoom extends ICalcIdentificatable {
    list: ICalcService[]
}

export interface ICalcLogic {
    list: ICalcRoom[]
}

const logic_dict : ICalcLogic = {
    list : [
        {
            id: "apartment",
            text: "Квартира",
            list: [
                {
                    id: "general",
                    text: "Генеральная",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 370;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 345;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 315;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 305;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 290;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 305;
                                } else if (squareValue < 90) {
                                    return 90 * 305;
                                } else {
                                    return squareValue * 300;
                                }
                            },
                            additional: [
                                {
                                    id: "wash_windows_outside",
                                    text: "Мытье окон снаружи",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1700;
                                        } else if (squareValue < 70 + 5) {
                                            return 1900;
                                        } else if (squareValue < 80 + 5) {
                                            return 2500;
                                        } else if (squareValue < 90) {
                                            return 3000;
                                        } else {
                                            return 5000;
                                        }
                                    }
                                },
                                {
                                    id: "wash_windows_inside",
                                    text: "Мытье окон на балконе внутри",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 2500;
                                        } else if (squareValue < 40 + 5) {
                                            return 2500;
                                        } else if (squareValue < 50 + 5) {
                                            return 2500;
                                        } else if (squareValue < 60 + 5) {
                                            return 2500;
                                        } else if (squareValue < 70 + 5) {
                                            return 2500;
                                        } else if (squareValue < 80 + 5) {
                                            return 4000;
                                        } else if (squareValue < 90) {
                                            return 6000;
                                        } else {
                                            return 10000;
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            id: "assoc",
                            text: "Ассоциальная",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 1100;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 1020;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 970;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 930;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 910;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 850;
                                } else if (squareValue < 90) {
                                    return 90 * 810;
                                } else {
                                    return squareValue * 750;
                                }
                            }
                        },
                        {
                            id: "party",
                            text: "Последствия вечеринки",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 520;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 470;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 460;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 430;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 420;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 420;
                                } else if (squareValue < 90) {
                                    return 90 * 420;
                                } else {
                                    return squareValue * 400;
                                }
                            },
                            additional: [
                                {
                                    id: "room_too_far",
                                    text: "Квартира находится вне черты города",
                                    fun: (squareValue: number) => {
                                        return 2500;
                                    }
                                }
                            ]
                        },
                        {
                            id: "presold",
                            text: "Предпродажная",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 300;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 295;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 290;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 285;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 280;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 275;
                                } else if (squareValue < 90) {
                                    return 90 * 270;
                                } else {
                                    return squareValue * 250;
                                }
                            },
                            additional: [
                                {
                                    id: "wash_balcony",
                                    text: "Мытье балкона",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 2500;
                                        } else if (squareValue < 40 + 5) {
                                            return 2500;
                                        } else if (squareValue < 50 + 5) {
                                            return 2500;
                                        } else if (squareValue < 60 + 5) {
                                            return 2500;
                                        } else if (squareValue < 70 + 5) {
                                            return 2500;
                                        } else if (squareValue < 80 + 5) {
                                            return 4000;
                                        } else if (squareValue < 90) {
                                            return 6000;
                                        } else {
                                            return 10000;
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    id: "support",
                    text: "Поддерживающая",
                    list: [
                        {
                            id: "light",
                            text: "Лайт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 950;
                                } else if (squareValue < 40 + 5) {
                                    return 1240;
                                } else if (squareValue < 50 + 5) {
                                    return 1530;
                                } else if (squareValue < 60 + 5) {
                                    return 1670;
                                } else if (squareValue < 70 + 5) {
                                    return 1960;
                                } else if (squareValue < 80 + 5) {
                                    return 2110;
                                } else if (squareValue < 90) {
                                    return 2175;
                                } else {
                                    return squareValue * 25;
                                }
                            },
                            additional: [
                                {
                                    id: "wash_windows_outside",
                                    text: "Мытье окон снаружи",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1700;
                                        } else if (squareValue < 70 + 5) {
                                            return 1900;
                                        } else if (squareValue < 80 + 5) {
                                            return 2500;
                                        } else if (squareValue < 90) {
                                            return 3000;
                                        } else {
                                            return 5000;
                                        }
                                    }
                                },
                                {
                                    id: "support_balcony",
                                    text: "Поддерживающая уборка на балконе",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1200;
                                        } else if (squareValue < 50 + 5) {
                                            return 1200;
                                        } else if (squareValue < 60 + 5) {
                                            return 1200;
                                        } else if (squareValue < 70 + 5) {
                                            return 2500;
                                        } else if (squareValue < 80 + 5) {
                                            return 4000;
                                        } else if (squareValue < 90) {
                                            return 6000;
                                        } else {
                                            return 10000;
                                        }
                                    }
                                },
                                {
                                    id: "iron_linen",
                                    text: "Погладить белье",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1300;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1600;
                                        } else if (squareValue < 70 + 5) {
                                            return 1700;
                                        } else if (squareValue < 80 + 5) {
                                            return 1800;
                                        } else if (squareValue < 90) {
                                            return 2500;
                                        } else {
                                            return 3000;
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 1230;
                                } else if (squareValue < 40 + 5) {
                                    return 1610;
                                } else if (squareValue < 50 + 5) {
                                    return 1980;
                                } else if (squareValue < 60 + 5) {
                                    return 2170;
                                } else if (squareValue < 70 + 5) {
                                    return 2550;
                                } else if (squareValue < 80 + 5) {
                                    return 2740;
                                } else if (squareValue < 90) {
                                    return 2830;
                                } else {
                                    return squareValue * 32;
                                }
                            },
                            additional: [
                                {
                                    id: "wash_windows_outside",
                                    text: "Мытье окон снаружи",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1700;
                                        } else if (squareValue < 70 + 5) {
                                            return 1900;
                                        } else if (squareValue < 80 + 5) {
                                            return 2500;
                                        } else if (squareValue < 90) {
                                            return 3000;
                                        } else {
                                            return 5000;
                                        }
                                    }
                                },
                                {
                                    id: "support_balcony",
                                    text: "Поддерживающая уборка на балконе",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1200;
                                        } else if (squareValue < 50 + 5) {
                                            return 1200;
                                        } else if (squareValue < 60 + 5) {
                                            return 1200;
                                        } else if (squareValue < 70 + 5) {
                                            return 2500;
                                        } else if (squareValue < 80 + 5) {
                                            return 4000;
                                        } else if (squareValue < 90) {
                                            return 6000;
                                        } else {
                                            return 10000;
                                        }
                                    }
                                },
                                {
                                    id: "iron_linen",
                                    text: "Погладить белье",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1300;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1600;
                                        } else if (squareValue < 70 + 5) {
                                            return 1700;
                                        } else if (squareValue < 80 + 5) {
                                            return 1800;
                                        } else if (squareValue < 90) {
                                            return 2500;
                                        } else {
                                            return 3000;
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            id: "premium",
                            text: "Премиум",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 2262;
                                } else if (squareValue < 40 + 5) {
                                    return 2958;
                                } else if (squareValue < 50 + 5) {
                                    return 3654;
                                } else if (squareValue < 60 + 5) {
                                    return 4002;
                                } else if (squareValue < 70 + 5) {
                                    return 4698;
                                } else if (squareValue < 80 + 5) {
                                    return 5046;
                                } else if (squareValue < 90) {
                                    return 5220;
                                } else {
                                    return squareValue * 58;
                                }
                            },
                            additional: [
                                {
                                    id: "wash_windows_outside",
                                    text: "Мытье окон снаружи",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1700;
                                        } else if (squareValue < 70 + 5) {
                                            return 1900;
                                        } else if (squareValue < 80 + 5) {
                                            return 2500;
                                        } else if (squareValue < 90) {
                                            return 3000;
                                        } else {
                                            return 5000;
                                        }
                                    }
                                },
                                {
                                    id: "support_balcony",
                                    text: "Поддерживающая уборка на балконе",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1200;
                                        } else if (squareValue < 40 + 5) {
                                            return 1200;
                                        } else if (squareValue < 50 + 5) {
                                            return 1200;
                                        } else if (squareValue < 60 + 5) {
                                            return 1200;
                                        } else if (squareValue < 70 + 5) {
                                            return 2500;
                                        } else if (squareValue < 80 + 5) {
                                            return 4000;
                                        } else if (squareValue < 90) {
                                            return 6000;
                                        } else {
                                            return 10000;
                                        }
                                    }
                                },
                                {
                                    id: "iron_linen",
                                    text: "Погладить белье",
                                    fun: (squareValue: number) => {
                                        if (squareValue < 30 + 5) {
                                            return 1300;
                                        } else if (squareValue < 40 + 5) {
                                            return 1400;
                                        } else if (squareValue < 50 + 5) {
                                            return 1500;
                                        } else if (squareValue < 60 + 5) {
                                            return 1600;
                                        } else if (squareValue < 70 + 5) {
                                            return 1700;
                                        } else if (squareValue < 80 + 5) {
                                            return 1800;
                                        } else if (squareValue < 90) {
                                            return 2500;
                                        } else {
                                            return 3000;
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    id: "build",
                    text: "Послестроительная",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 400;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 395;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 390;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 385;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 380;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 375;
                                } else if (squareValue < 90) {
                                    return 90 * 370;
                                } else {
                                    return squareValue * 350;
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: "office",
            text: "Офис",
            list: [
                {
                    id: "general",
                    text: "Генеральная",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 220;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 215;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 215;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 210;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 210;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 205;
                                } else if (squareValue < 90) {
                                    return 90 * 205;
                                } else {
                                    return squareValue * 200;
                                }
                            }
                        }
                    ]
                },
                {
                    id: "support",
                    text: "Поддерживающая",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue <= 15) {
                                    return 900;
                                } else if (squareValue < 20 + 5) {
                                    return 940;
                                } else if (squareValue < 30 + 5) {
                                    return 990;
                                } else if (squareValue < 40 + 5) {
                                    return 1000;
                                } else if (squareValue < 50 + 5) {
                                    return 1150;
                                } else if (squareValue < 60 + 5) {
                                    return 1250;
                                } else if (squareValue < 70) {
                                    return 1400;
                                } else {
                                    return squareValue * 20;
                                }
                            }
                        }
                    ]
                },
                {
                    id: "build",
                    text: "Послестроительная",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 250;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 240;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 235;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 235;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 230;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 225;
                                } else if (squareValue < 90) {
                                    return 90 * 220;
                                } else {
                                    return squareValue * 200;
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: "market",
            text: "Торговая точка",
            list: [
                {
                    id: "general",
                    text: "Генеральная",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 220;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 215;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 215;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 210;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 210;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 205;
                                } else if (squareValue < 90) {
                                    return 90 * 205;
                                } else {
                                    return squareValue * 200;
                                }
                            }
                        }
                    ]
                },
                {
                    id: "support",
                    text: "Поддерживающая",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 900;
                                } else if (squareValue < 40 + 5) {
                                    return 900;
                                } else if (squareValue < 50 + 5) {
                                    return 950;
                                } else if (squareValue < 60 + 5) {
                                    return 1020;
                                } else if (squareValue < 70 + 5) {
                                    return 1090;
                                } else if (squareValue < 80 + 5) {
                                    return 1160;
                                } else if (squareValue < 90 + 5) {
                                    return 1240;
                                } else if (squareValue < 100) {
                                    return 1310;
                                } else {
                                    return squareValue * 15;
                                }
                            }
                        }
                    ]
                },
                {
                    id: "build",
                    text: "Поддерживающая",
                    list: [
                        {
                            id: "default",
                            text: "Стандарт",
                            fun: (squareValue: number) => {
                                if (squareValue < 30 + 5) {
                                    return 30 * 275;
                                } else if (squareValue < 40 + 5) {
                                    return 40 * 270;
                                } else if (squareValue < 50 + 5) {
                                    return 50 * 270;
                                } else if (squareValue < 60 + 5) {
                                    return 60 * 265;
                                } else if (squareValue < 70 + 5) {
                                    return 70 * 260;
                                } else if (squareValue < 80 + 5) {
                                    return 80 * 255;
                                } else if (squareValue < 90) {
                                    return 90 * 255;
                                } else {
                                    return squareValue * 250;
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ]
}


export function getLocalRoomType(labor: ILabor) {
    return logic_dict.list.find(e => e.id === labor.room_type).text;
}

export function getLocalService(labor: ILabor) {
    return logic_dict.list.find(e => e.id === labor.room_type)
        .list.find(e => e.id === labor.service).text;
}

export function getLocalSubservice(labor: ILabor) {
    return logic_dict.list.find(e => e.id === labor.room_type)
        .list.find(e => e.id === labor.service)
        .list.find(e => e.id === labor.subservice).text;
}

export function getLocalAdditional(labor: ILabor, additional_id: string) {
    return logic_dict.list.find(e => e.id === labor.room_type)
        .list.find(e => e.id === labor.service)
        .list.find(e => e.id === labor.subservice)
        .additional.find(e => e.id === additional_id).text
}

export function calculate(labor: ILabor) : ICalculationResult {
    const subserviceSection = logic_dict.list.find(e => e.id === labor.room_type)
        .list.find(e => e.id === labor.service)
        .list.find(e => e.id === labor.subservice);


    const primarySum = subserviceSection.fun(labor.room_square)

    const sectionAdditionals = subserviceSection.additional ?? []

    let additionalSum = 0;
    if (labor.additional_list.length > 0 && sectionAdditionals && sectionAdditionals.length > 0) {
        labor.additional_list.forEach(id => {
            const foundItem = sectionAdditionals.filter(item => id === item.id).pop()
            if (foundItem) {
                additionalSum += foundItem.fun(labor.room_square)
            }
        })
    }

    const mainSum = primarySum + additionalSum;
    const discountWithUsePoints = Math.floor(Math.min(mainSum * 0.15, labor.usedPoints))

    return {
        id: labor.id,

        primarySum: primarySum,
        additionalsSum: additionalSum,
        mainSum: mainSum,

        promoQuantifier: labor.promoQuantifier,
        promoDiscountValue: mainSum - mainSum * labor.promoQuantifier,

        pointsDiscountValue: discountWithUsePoints,

        maxAvailablePointsValue: Math.floor(mainSum * 0.15),

        totalSum: mainSum * labor.promoQuantifier - discountWithUsePoints
    }
}

export function getAdditionals(labor: ILabor): ICalcBase[] {
    return logic_dict.list.find(e => e.id === labor.room_type)
        .list.find(e => e.id === labor.service)
        .list.find(e => e.id === labor.subservice)?.additional ?? []
}

export function getSubservicesByService(roomType: RoomType, serviceType: ServiceType) : ICalcService {
    return logic_dict.list.find(e => e.id === roomType)
        .list.find(e => e.id === serviceType);
}


/**
 * Если время возможно для бронирования сейчас
 * @param date
 */
export const isTimePrefferedByWorkHours = (date: Date) : boolean => {
    const startWorkHour = 8;
    const endWorkHour = 19;

    return date.getHours() >= startWorkHour && date.getHours() < endWorkHour;
}

/**
 * Ближайшая возможная дата бронирования времени клинера
 * @param date
 */
export const preferDate = (date: Date = new Date()): Date => {
    const startWorkHour = 8;
    const endWorkHour = 19;
    const hoursOffset = 3;

    const isBeforeNoon = date.getHours() < 12;
    const isBeforeStartWork = date.getHours() < startWorkHour;

    if (isBeforeStartWork) {
        date.setHours(startWorkHour, 0, 0);
    }

    if (isBeforeNoon) {
        date.setHours(date.getHours() + hoursOffset);
    } else {
        date.setDate(date.getDate() + 1);
        date.setHours(startWorkHour + 1, 0, 0);
    }

    return date;
}


export default calculate;