
import s from './FormOrders.module.css';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { SpisokModal } from '../../pages/main/MainPage/SpisokModal';
import { ReactComponent as Check } from '../../images/checkmark.svg'
import Axios from 'axios'
import PhoneInput from './PhoneInput/PhoneInput';

const FormOrders = () => {

    type TFormValues = {
        phone: string;
        name: string;
        type: string;
      };

    const [openSpisok, setOpenSpisok] = useState(false);
    const [openSpisokId, setOpenSpisokId] = useState('');
  
    const [check, setCheck] = useState(false);
    const [mask, setMask] = useState("+7 900 000 00 00");
 
    const { register, handleSubmit, watch, setValue } = useForm<TFormValues>({ mode: 'onChange' });
    
    const onSubmit = async (formData: TFormValues) => {
        if (!watch('name') || !watch('phone') || watch('phone').length !== 9) {
          return null;
        } else if(!check) {
          return null;
        } else {
          try {
            const dataToSend = {
              'order-info': {
                phone: formData.phone = mask,
                name: formData.name,
                type: formData.type || '',
              }
            };
      
            await Axios.post(api, dataToSend)
              .then((response) => {
                console.log(response);
              });
          } catch (error) {
            console.log('Error:', error);
          }
        }
      };

      const api = `https://402.m-dels.ru/order`;
      
       // функция для ввода телефона

  const handleSpisokClose = () => {
    setOpenSpisok(false);
    setOpenSpisokId('')
  }

   // функция для вкл/выкл принятия пользовательского соглашения
   const handleCheck = () => {
    setCheck(!check);
  };
  return (
    <div id='formorders' className={s.Mobile}>
      <div className={s.FormOrders}>
        <div className={s.container}>
          <div className={s.logo}>Заказать уборку</div>
          <div className={s.btns}>
            <div >
              <input {...register('name')} type="text" placeholder='Имя' className={s.nameForm} />
            </div>
            <div >
              <PhoneInput/>
            </div>
          
            <div className={s.btn1}>
              <button type='submit' onSubmit={handleSubmit(onSubmit)}  className={s.btn}>
              Заказать
              </button>
            </div>
            <div className={s.btnSecond}>
              <div className={s.btnTitle}>
              Нажимая на кнопку Заказать Вы принимаете <span className={s.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('offer')}}>пользовательское соглашение </span> и соглашаетесь на <span className={s.link} onClick={() => {setOpenSpisok(true); setOpenSpisokId('politic')}}>обработку персональных данных.</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpisokModal 
        open={openSpisok}
        modal_id={openSpisokId}
        onClose={handleSpisokClose} 
      />
    </div>
  )
}

export default FormOrders